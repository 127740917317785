<template>
  <div class="root">
    <div :class="length == 2 ? 'parent padding' : 'parent'" ref="parent">
      <div :class="box" :style="cssVar">
        <div class="item" v-for="(item, i) in list" :key="i">
          <div
            class="imgbox"
            :style="{ 'background-image': `url(${item.levelImg|| item.ornamentLevelImg})` }"
          >
            <img :src="item.imageUrl|fullPath" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parentTop: -1,
      parentBottom: -1,
      // 初始速度
      speed: 20,
      allowScroll: true,
      // 所有数据
      source: [...this.data],
      // 结果
      result: this.res,
      // 动画时间
      time: this.playTime,
      res_element: {},
      result_localtion: new Map(),
      //抽奖结果对象
      target: {},
      //用于轮播的数据
      list: [],
      //控制动画播放的类名
      box: ["box"],
    };
  },
  watch: {},
  methods: {
    //宝箱翻倍
    add(arr) {
      if (arr.length < 10 && arr.length >= 1) {
        arr.push(...arr);
        return this.add(arr);
      } else {
        return arr;
      }
    },
  },
  props: {
    data: Array,
    res: String,
    playTime: Number,
    round: Number,
    length: Number,
  },
  // components: {
  //   vueSeamlessScroll,
  // },
  beforeMount() {
    // this.time = this.playTime;
  },
  mounted() {
    console.log("宝箱数据", this.data);
    // console.log("lotteryres", this.res)
    // console.log('cssVar',this.cssVar);
    this.$emit("playPkAudio");
    this.data = this.add(this.data);
    this.list = [...this.data];
    this.target = this.source.filter((item) => {
      return item.ornamentId == Number(this.res);
    });
    this.list.push(this.target[0]);
    console.log("动画数据", this.list);

    
      setTimeout(() => {
        this.box = ["box", "transition"];
      }, 100);
      setTimeout(() => {
        this.$emit("scrollStop", this.result);
      }, 3100);
    
  },

  computed: {
    cssVar() {
      return {
        "--H": 1 - this.list.length,
        "--h": 110 - this.list.length * 118 + "px",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  // border: 1px solid greenyellow;
  //position: relative;
  // width: 80%;
  // border: 1px solid red;
  width: 100%;
  // max-width: 130.66px;
  height: 98px;
  @media (max-width: 550px) {
    height: 18vw;
  }
}

.vueScroll {
  //display: none;
  // position: relative;
}

// .img {
//   position: relative;
//   border-top: 0.5px solid black;
//   overflow: hidden;
// }

.parent {
  // border: 2px solid red;
  box-shadow: 0 0 5px #dddbda;
  box-sizing: border-box;
  position: relative;
  width: 96%;
  margin: 0 auto;
  height: 110px;
  @media (max-width: 550px) {
    height: 20vw;
    padding: 2vw 0 0;
  }
  padding: 10px 0 0;
  overflow: hidden;
}
.padding {
  padding: 10px 46px 0;
}
.root {
  position: absolute;
  width: 100%;
  // border: 3px solid white;
}
.transition {
  transform: translateY(var(--h));
  @media (max-width: 550px) {
    transform: translateY(calc(var(--H) * 22vw - 2vw));
  }
  // transform: translateY(-500px);
  transition: all 3s ease;
}
.item {
  margin-bottom: 20px;
  @media (max-width: 550px) {
    margin-bottom: 4vw;
  }
}
.txt {
}
.box {
  // line-height: --H;
}
.imgbox {
  width: 100%;
  background-size: 100% 100%;
  // border: 1px solid red;
}
</style>
